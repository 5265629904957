import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FiGlobe } from 'react-icons/fi';
import { toast, ToastContainer } from 'react-toastify';
import { fetchResetLinkData, updateUserDetails, checkUserName } from '../utils/apis'; // Import checkUserName API
import AntroLogo from "../assets/images/antrologo.png";
import PadlockIcon from "../assets/images/Padlock.svg";
// import '../assets/styles/pages/ResetUsername.scss';

const ResetUsername = () => {
  const { token } = useParams();
  const [secretCode, setSecretCode] = useState('');
  const [newUsername, setNewUsername] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [touched, setTouched] = useState({ newUsername: false });
  const [showVisitWebsite, setShowVisitWebsite] = useState(false);


  useEffect(() => {
    if (!token) {
      window.location.href = '/invalid-code';
    } else {
      setSecretCode(token);
      checkLink(token);
    }
  }, [token]);

  const checkLink = async (token) => {
    try {
      const data_type = "reset_username";
      const result = await fetchResetLinkData(data_type, token);
      if (!result.success && !result.errors.link_verified) {
        window.location.href = '/invalid-code';
      }
    } catch (error) {
      console.log('Error checking link:', error);
      window.location.href = '/invalid-code';
    }
  };

  // Validate the username and check availability with the API
  const validateUsername = async (username) => {
    let errors = [];

    if (username.length === 0) errors.push('New username is required.');
    if (/\s/.test(username)) errors.push("Username cannot contain spaces.");
    if (/[^a-zA-Z0-9_]/.test(username)) {
      errors.push("Username can only contain letters, numbers, and underscores.");
    }
    if (username.length < 5) errors.push('Username must be at least 5 characters long.');
    if (username.length > 30) errors.push('Username must not be more than 30 characters long.');

    // Check if the username is available via API call
    if (errors.length === 0) {
      try {
        const response = await checkUserName(username);
        if (!response?.data?.available) {
          errors.push("Username is already taken.");
        }
      } catch (error) {
        errors.push("Error checking username availability.");
        console.log("Error checking username availability:", error);
      }
    }

    return errors;
  };

  const checkFormValidity = async () => {
    const usernameErrors = await validateUsername(newUsername);
    setErrors({ newUsername: usernameErrors });
    setIsSubmitEnabled(usernameErrors.length === 0);
  };


  const handleInputChange = (event) => {
    setNewUsername(event.target.value);
    setTouched({ ...touched, newUsername: true }); // Mark input as touched
    checkFormValidity();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const usernameErrors = await validateUsername(newUsername);
    setErrors({ newUsername: usernameErrors });

    if (usernameErrors.length > 0) {
      return;
    }

    try {
      const data_type = "reset_username";
      const result = await updateUserDetails(data_type, secretCode, newUsername);

      if (result.success) {
        toast.success('Your username has been reset successfully.');

        // Show redirection message
        document.querySelector('.container').innerHTML = `
          <h2>Username Reset</h2>
          <p>Your username has been reset successfully.</p>
        `;
        setShowVisitWebsite(true); 
        // Redirect to home page after 5 seconds using useNavigate
        // setTimeout(() => {
        //   navigate("/"); // Redirect to home page
        // }, 5000);
      } else {
        toast.error(result.message || 'An error occurred. Please try again.');
      }
    } catch (error) {
      toast.error('Your username was not reset successfully. Please try again.');
      console.log('API Error:', error);
    }
  };

  return (
    <div className="main-update-username">
      <div className="header">
        <img src={AntroLogo} alt="Antro Logo" className="logo" />
      </div>
      <img src={PadlockIcon} alt="Padlock Icon" className="lock-icon" />
      <div className="container">
        <h2>Reset Username</h2>
        <form id="newUsernameForm" className="form-corporate-newUsername" onSubmit={handleSubmit}>
          <div className="input-wrapper">
            <input
              className={`username-input ${errors.newUsername?.length > 0 ? 'error' : ''}`}
              type="text"
              id="newUsername"
              maxLength="30"
              placeholder="New Username"
              value={newUsername}
              onChange={handleInputChange}
              onBlur={checkFormValidity}
            />
          </div>
          {touched.newUsername && errors.newUsername?.length > 0 && (
            <div className="error-message">
              {errors.newUsername.map((error, index) => <div key={index}>{error}</div>)}
            </div>
          )}
          <div className="main-submit-btn">
            <button type="submit" id="submitButton" disabled={!isSubmitEnabled}>
              Submit
            </button>
          </div>
        </form>
      </div>
      {showVisitWebsite && (
      <div className="visit-website">
        <div className="visit-website-content">
          <p className="visit-website-text">
            Learn more about our products and services on our official website.
          </p>
          <button type="button" className="visit-website-button"
            onClick={() => window.location.href = '/'}
          >
            <FiGlobe className="button-icon" />
            Visit Antrocorp Website
          </button>
        </div>
      </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default ResetUsername;
