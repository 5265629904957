// Create a Home page
import React, { useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import '../assets/styles/pages/Home.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-multi-carousel/lib/styles.css';
import Section2Security1 from "../assets/images/homepage/Frame 626935.png"
import Section2Security2 from "../assets/images/homepage/section-2-security-2.png"
import IdentityManagement1 from "../assets/images/homepage/identity_management_1.png"
import IdentityManagement2 from "../assets/images/homepage/identity_management_2.png"
import IdentityManagement3 from "../assets/images/homepage/identity_management_3.png"
import IdentityManagement4 from "../assets/images/homepage/identity_management_4.png"
import IdentityManagement5 from "../assets/images/homepage/identity_management_5.png"
import IdentityManagement6 from "../assets/images/homepage/identity_management_6.png"
import NewsSub1 from "../assets/images/homepage/news_sub_1.png"
import NewsSub2 from "../assets/images/homepage/news_sub_2.png"
import NewsSub3 from "../assets/images/homepage/news_sub_3.png"
import ImageCarousel from '../components/common/Carousel1';
import { LuBadgeCheck } from "react-icons/lu";
import Mail from "../assets/images/homepage/Group.png"
import { GoShieldCheck } from "react-icons/go";
import { FaPlay } from "react-icons/fa";
import { FaGears } from "react-icons/fa6";

const Home = () => {
  const navigate = useNavigate();
  // const responsive = {
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 3,
  //     slidesToSlide: 3 // optional, default to 1.
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 2,
  //     slidesToSlide: 2 // optional, default to 1.
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1,
  //     slidesToSlide: 1 // optional, default to 1.
  //   }
  // };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const scrollRef = useRef(null);
  useEffect(() => {
    const scrollContainer = scrollRef.current;
  if (scrollContainer) {
    const scroll = () => {
      scrollContainer.scrollLeft += 1;
      if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth - scrollContainer.clientWidth) {
        scrollContainer.scrollLeft = 0;
      }
    };
    const intervalId = setInterval(scroll, 20);
    return () => clearInterval(intervalId);
  }
  }, []);

  const blogPostsItems = [
    <div className="small-post">
      <div className="post-image-placeholder">
        <LazyLoadImage
          className="reactangle"
          src={IdentityManagement3}
          alt="reactangle"
          delayTime={1000}
          threshold={100}
          effect="blur" 
        />
      </div>
      <div className="post-content">
        <p className="post-date">July 10, 2024</p>
        <h3 className="post-title">
        The Future of Identity Protection: Trends Shaping Cybersecurity
        </h3>
      </div>
    </div>,
    <div className="small-post">
      <div className="post-image-placeholder">
        <LazyLoadImage
          className="reactangle"
          src={IdentityManagement4}
          alt="reactangle"
          delayTime={1000}
          threshold={100}
          effect="blur"
        />
      </div>
      <div className="post-content">
        <p className="post-date">July 10, 2024</p>
        <h3 className="post-title">
        5 Key Cybersecurity Practices Every Business Should Adopt
        </h3>
      </div>
    </div>,
    <div className="small-post">
      <div className="post-image-placeholder">
        <LazyLoadImage
          className="reactangle"
          src={IdentityManagement5}
          alt="reactangle"
          delayTime={1000}
          threshold={100}
          effect="blur" // Optional: adds a blur effect during loading
        />
      </div>
      <div className="post-content">
        <p className="post-date">July 10, 2024</p>
        <h3 className="post-title">
        The Role of AI in Strengthening Digital Security
        </h3>
      </div>
    </div>,
    <div className="small-post">
      <div className="post-image-placeholder">
        <LazyLoadImage
          className="reactangle"
          src={IdentityManagement6}
          alt="reactangle"
          delayTime={1000}
          threshold={100}
          effect="blur" // Optional: adds a blur effect during loading
        />
      </div>
      <div className="post-content">
        <p className="post-date">July 10, 2024</p>
        <h3 className="post-title">
        Understanding Zero Trust: A New Approach to Cyber Defense
        </h3>
      </div>
    </div>
  ];

  const newsPostsItems = [
    <div className="small-post">
      <div className="post-image-placeholder">
        <img src={NewsSub1} alt='post-img'/>
      </div>
      <div className="post-content">
        <p className="post-insights">Industry Insights</p>
        <h3 className="post-title">
          Understanding CCPA: Best Practices for Data Protection and Compliance
        </h3>
        <p className="post-date">July 10, 2024 <span><FaPlay /> Play</span></p>
      </div>
    </div>,
    <div className="small-post">
      <div className="post-image-placeholder">
        <img src={NewsSub2} alt='post-img'/>
      </div>
      <div className="post-content">
        <p className="post-insights">Industry Insights</p>
        <h3 className="post-title">
          Mitigating Insider Threats: Effective Identity Governance Strategies
        </h3>
        <p className="post-date">July 10, 2024 <span><FaPlay /> Play</span></p>
      </div>
    </div>,
    <div className="small-post">
      <div className="post-image-placeholder">
        <img src={NewsSub3} alt='post-img'/>
      </div>
      <div className="post-content">
        <p className="post-insights">Industry Insights</p>
        <h3 className="post-title">
          Biometric Authentication: Balancing Convenience and Security
        </h3>
        <p className="post-date">July 10, 2024 <span><FaPlay /> Play</span></p>
      </div>
    </div>
  ];

  return (
    <div className="home">
      <div className='section-1'>
        <div>
          <ImageCarousel />
        </div>
      </div>
      <div className="cybersecurity-partner-container">
        <div className="text-section">
          <div className='text-section-inner'>
            <button className="about-us-btn">About Us</button>
            <h2>Securing Tomorrow, Today: Meet Antro, Your Cybersecurity Partner</h2>
            <p>
              An innovative cybersecurity solutions company specializing in identity and access management. We offer real-time,
              process-driven solutions designed to protect against identity theft and impersonation.
            </p>
          </div>
        </div>
        <div className="image-section">
          <div className="image-box">
          <LazyLoadImage
            className="reactangle"
            src={Section2Security2}
            alt="reactangle"
            delayTime={50000}
            threshold={10}
            effect="blur"
          />
          </div>
        </div>
      </div>
      <div className="section-4-container">
        <div className="text-section">
          <div className='text-section-inner'>
          <div className='heading-testimonial-home'>
          <span>EXPLORE</span>
          </div>
            <div className='heading-testimonial-home-sub'>Unveiling Antro PAI: Your Shield Against Identity Threats</div>
            <p>
              Antro's Pre-Authenticated Identity (PAI) model ensures unique,
              dynamic identity verification, preventing fraud and unauthorized access.
            </p>
          </div>
        </div>
        <div className="image-section">
          <div className='image-section-1'>
            <div className="image-box">
              <div className='image-box-heading'>
                <div className='heading-icon'><LuBadgeCheck /></div>
                <div className='heading-text'>Dynamic Identity Verification:</div>
              </div>
              <div className='heading-para'>
                Continuously evolves to match the latest security protocols, offering adaptive measures for each transaction.
              </div>
            </div>
            <div className="image-box">
              <div className='image-box-heading'>
                <div className='heading-icon'><FaGears /></div>
                <div className='heading-text'>Seamless Integration:</div>
              </div>
              <div className='heading-para'>
                Easily integrates with your existing systems, enhancing security without disrupting your workflow.
              </div>
            </div>
            <div className="image-box">
              <div className='image-box-heading'>
                <div className='heading-icon'><GoShieldCheck /></div>
                <div className='heading-text'>Proactive Fraud Prevention:</div>
              </div>
              <div className='heading-para'>
                Detects and prevents unauthorized access with real-time monitoring and alerts, safeguarding your data and assets.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-posts-container">
        <div className="header">
        <div className='heading-testimonial-home'>
          INSIGHT VAULT
          </div>
          <h1>Identity Management</h1>
        </div>
        <div className="latest-posts-section">
          <div className='section-title'>Our Latest Blog Posts</div>
          <div className="posts-grid">
            {/* Main post cards */}
            <div className='large-post-outer'>
              <div className="large-post">
                <div className="post-image-placeholder">
                <LazyLoadImage
                    className="reactangle"
                    src={IdentityManagement1}
                    alt="reactangle"
                    delayTime={5000}
                    threshold={100}
                    height={400}
                    width={400}
                    effect="blur"
                  />
                </div>
                <div className="post-content">
                  <p className="post-date">July 10, 2024</p>
                  <h3 className="post-title">
                    The Future Of Identity Management: Trends To Watch In 2024
                  </h3>
                  <p className="post-description">
                    Exploring the latest innovations and what they mean for your business.
                  </p>
                </div>
              </div>
              <div className="large-post">
                <div className="post-image-placeholder">
                  <LazyLoadImage
                    className="reactangle"
                    src={IdentityManagement2}
                    alt="reactangle"
                    delayTime={5000}
                    threshold={100}
                    height={400}
                    width={400}
                    effect="blur"
                  />
                </div>
                <div className="post-content">
                  <p className="post-date">July 10, 2024</p>
                  <h3 className="post-title">
                    Strengthening Your Cybersecurity With Biometric Authentication
                  </h3>
                  <p className="post-description">
                    How biometric solutions are revolutionizing identity verification.
                  </p>
                </div>
              </div>
            </div>
            <div className="small-posts">
              {blogPostsItems.map(item => {
                return item
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="news-updates-container">
        <div className="latest-posts-section">
          <div className='section-title'>News & Updates</div>
          <div className="posts-grid">
            {/* Main post cards */}
            <div className='large-post-outer'>
              <div className="large-post">
                <div className="post-image-placeholder">
                  <LazyLoadImage
                    className="reactangle"
                    src={Section2Security1}
                    alt="reactangle"
                    delayTime={5000}
                    threshold={100}
                    height={400}
                    width={400}
                    effect="blur"
                  />
                </div>
                <div className="post-content">
                  <div>
                    <p className="post-insights">Industry Insights</p>
                  </div>
                  <div>
                    <h3 className="post-title">
                      The Future Of Identity Management: Trends To Watch In 2024
                    </h3>
                    <p className="post-description">
                      Exploring the latest innovations and what they mean for your business.
                    </p>
                  </div>
                  <div>
                    <p className="post-date">July 10, 2024</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="small-posts">
              {newsPostsItems.map(item => {
                return item
              })}
            </div>
          </div>
        </div>
      </div>
      <div className='main-connect-section'>
      <section className="connect-section">
        <div className="icon">
        <img src={Mail} alt='mail-img'/>
        </div>
        <div className='connect-section-heading'>Connect with Confidence</div>
        <p>Have questions or need support? Contact our team for assistance. Feel free to reach out for expert guidance and personalized assistance.</p>
        <button className="cta-button" onClick={() => navigate("/contact-us")}>Reach Out!</button>
      </section>
    </div> 
    </div> 

  );
}

export default Home;