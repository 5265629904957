import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import Flagship1 from '../../assets/images/portfolio/flagship1.png';
import Profile from '../../assets/images/portfolio/user-profile.png';
import WatchProfile from '../../assets/images/portfolio/watch_user-profile .png';
import WarningProfile from '../../assets/images/portfolio/warning_user-profile.png';
import Notification from '../../assets/images/portfolio/notification_user-profile.png';
import CarbonProfile from '../../assets/images/portfolio/carbon_user-profile.png';

import '../../assets/styles/pages/services/FlagshipProduct.scss';

const FlagshipProduct = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false, amount: 0.5 });

  return (
    <div className="flagship-product" ref={ref}>
      <div className='flagship-sub-heading'>UNLOCK THE POWER OF ANTRO</div>
      <motion.h2
        initial={{ opacity: 0, y: -50 }}
        animate={isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        Our Flagship Product: Antro
      </motion.h2>

      <div className="flagship-product__content">
        <motion.div
          className="flagship-product__text"
          initial={{ opacity: 0, x: -50 }}
          animate={isInView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <p>
            <span className='heading'>A</span>ntro is our ground-breaking identity verification platform, designed to revolutionize how individuals and organisations manage digital identities.
          </p>
          <ul>
            <li><img src={Profile} alt="profile" className="icon" /> Comprehensive Digital Profiles – Detailed records of identities for individuals and organisations.</li>
            <li><img src={WatchProfile} alt="watch-profile" className="icon" />Real-Time Verification – Instant identity and background checks.</li>
            <li><img src={CarbonProfile} alt="Icon 1" className="icon" />Network Mapping – Visualize network connections to assess risks.</li>
            <li><img src={WarningProfile} alt="Icon 1" className="icon" />Risk Assessment – AI-driven analysis to flag potential identity risks.</li>
            <li><img src={Notification} alt="Icon 1" className="icon" />Customizable Solutions – Tailored to meet diverse enterprise and individual needs.</li>
          </ul>
        </motion.div>

        <motion.div
          className="flagship-product__image"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={isInView ? { opacity: 1, scale: 1 } : {}}
          transition={{ duration: 1.2 }}
        >
          <img src={Flagship1} alt="Antro Product" />
        </motion.div>
      </div>
    </div>
  );
}

export default FlagshipProduct;
