import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { FaStar } from "react-icons/fa";
import IphoneImg from "../assets/images/product-info/Iphone-Product-info.png";
import StarImg from "../assets/images/product-info/Star 4.svg";
import Section5Img from "../assets/images/product-info/section-5img.svg";
import StarSection6 from "../assets/images/product-info/section6-star.svg";
import Section1Bg from "../assets/images/product-info/section1bg.png";
import Section1People from "../assets/images/product-info/section1-people.png";
import LampDuotoneLine from "../assets/images/product-info/Lamp_duotone_line.png";
import { FiCheckCircle } from "react-icons/fi";
import IcRoundDomainVerification from "../assets/images/product-info/ic_round-domain-verification.png";
import ClarityNodesLine from "../assets/images/product-info/clarity_nodes-line.png";
import ComputerCloud from "../assets/images/product-info/computer-cloud.png";
import IconamoonShieldYesLight from "../assets/images/product-info/iconamoon_shield-yes-light.png";
import Flag02 from "../assets/images/product-info/flag-02.png";
import PhVault from "../assets/images/product-info/ph_vault.png";
import MaterialSymbolsQrCodeScannerRounded from "../assets/images/product-info/material-symbols_qr-code-scanner-rounded.png";
import UserSwitch from "../assets/images/product-info/user-switch.png";
import mdiInvoiceTextCheckOutline from "../assets/images/product-info/mdi_invoice-text-check-outline.png";
import Iphoneimg2 from "../assets/images/product-info/section7-phoneimg.png";
import ThumbIcon from "../assets/images/product-info/ThumbPrint.png"
import IdentityIcon from "../assets/images/product-info/IdentityImg.png"
import TechIcon from "../assets/images/product-info/tech.png"
import SecurityIcon from "../assets/images/product-info/security.png"
import ApplicationsIcon from "../assets/images/product-info/applications.png"
import LensIcon from "../assets/images/product-info/lens.png"
import MobileCarousel from "../components/common/MobileCarousel";
import "../assets/styles/pages/ProductInfo.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../assets/styles/components/carousel.scss";


const section1_styling = {
  sectionVariants: {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeOut" } },
  },
  backgroundVariants: {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1.5, ease: "easeIn" } },
  },
  buttonVariants: {
    hover: { scale: 1.1, transition: { yoyo: Infinity } },
  },
  headingVariants: {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1, ease: "easeOut" } },
  },
  subHeadingVariants: {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1, ease: "easeOut" } },
  },
  ratingVariants: {
    hidden: { opacity: 0, y: 50, scale: 0.8 },
    visible: { opacity: 1, y: 0, scale: 1, transition: { duration: 1, ease: "easeOut", bounce: 0.3 } },
  },
};
const section3_styling = {
  sectionVariants: {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeOut" } },
  },
  textBoxVariants: {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 1.2, ease: "easeOut" } },
  },
  starVariants: {
    hidden: { opacity: 0, rotate: -45, scale: 0.5 },
    visible: { opacity: 1, rotate: 0, scale: 1, transition: { duration: 1, ease: "easeOut" } },
  },
  phoneVariants: {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 1.5, ease: "easeOut" } },
  },
  tickBoxVariants: {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0, transition: { duration: 1, ease: "easeOut", staggerChildren: 0.3 } },
  },
  tickItemVariants: {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.8, ease: "easeOut" } },
  },
};

const ProductInfo = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className="product-home">
      <motion.div className="section-1" variants={section1_styling.sectionVariants} initial="hidden" animate="visible">
        {/* Background Image Animation */}
        <motion.div className="background-container-product-info" variants={section1_styling.backgroundVariants}>
          <img src={Section1Bg} alt="Background" />
        </motion.div>

        <div className="section-1-inner">
          <div className="section-1-2">
            {/* Button with Hover Animation */}
            <motion.button className="cta-button" variants={section1_styling.buttonVariants} whileHover="hover">
              <span>
                <img src={LampDuotoneLine} alt="Lamp Icon" />
              </span>
              Patented Innovation
            </motion.button>

            {/* Heading with Slide-In Animation */}
            <motion.h1 variants={section1_styling.headingVariants}>Antro-PAI</motion.h1>

            {/* Subheading with Slide-In Animation from Right */}
            <motion.div className="sub-heading" variants={section1_styling.subHeadingVariants}>
              The Future of Identity Verification Starts Here
            </motion.div>

            {/* Rating Section with Bounce Animation */}
            <motion.div className="rating-outer" variants={section1_styling.ratingVariants}>
              <div className="rating-inner">
                <img src={Section1People} alt="People" />
              </div>
              <div>
                <div className="rating-text">Our Happy Customers</div>
                <div className="rating">
                  <span className="star">
                    <FaStar />
                  </span>{" "}
                  4.9 <span className="rating-text-inner"></span>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>
      <motion.div className="section-3" variants={section3_styling.sectionVariants} initial="hidden" animate="visible">
        <div className="upper-div">
        <div className='upper-div-sub-heading'>MILESTONES OF SUCCESS</div>
          {/* Text Box with Slide-in Animation */}
          <motion.div className="left-box-section-3" variants={section3_styling.textBoxVariants}>
            <div className="left-box-section-3-heading">Charting the Course: Steps Towards a Secure Future</div>
            <div className="left-box-section-3-sub-heading">
              To counter the escalating cyber threat posed by false identities, we must deploy a secure, process-oriented identity solution.
              Accessible via a user-friendly mobile app, it guarantees secure identity verification for all.
            </div>
          </motion.div>
        </div>

        <div className="lower-div">
          <div className="left-div-lower">
            {/* Left Star Image with Rotate-In Animation */}
            <motion.div className="left-side-star" variants={section3_styling.starVariants}>
              <img src={StarImg} alt="Star" />
            </motion.div>

            {/* iPhone Image with Scale-In Animation */}
            <motion.div className="iphone" variants={section3_styling.phoneVariants}>
              <img src={IphoneImg} alt="iPhone" />
            </motion.div>

            {/* Right Star Image with Rotate-In Animation */}
            <motion.div className="right-side-star" variants={section3_styling.starVariants}>
              <img src={StarImg} alt="Star" />
            </motion.div>
          </div>

          <div className="right-div-lower">
            {/* Tick Box with Staggered Animation */}
            <motion.div className="left-sub-box" variants={section3_styling.tickBoxVariants}>
              <motion.div className="tick-text" variants={section3_styling.tickItemVariants}>
                <div className="frame-numb">1</div>
                <div className="main-sub-heading-third-div">
                  <div className="sub-heading-third-div">Process-Driven Identity Verification</div>
                  <div className="sub-content-third-div">
                    Establishing a systematic approach to identity verification to ensure authenticity and prevent fraud.
                  </div>
                </div>
              </motion.div>

              <motion.div className="tick-text" variants={section3_styling.tickItemVariants}>
                <div className="frame-numb">2</div>
                <div className="main-sub-heading-third-div">
                  <div className="sub-heading-third-div">Mobile App Accessibility</div>
                  <div className="sub-content-third-div">
                    Providing a convenient and secure mobile app that allows users to verify identities in real-time.
                  </div>
                </div>
              </motion.div>

              <motion.div className="tick-text" variants={section3_styling.tickItemVariants}>
                <div className="frame-numb">3</div>
                <div className="main-sub-heading-third-div">
                  <div className="sub-heading-third-div">Preventing Cyber Crimes</div>
                  <div className="sub-content-third-div">
                    Reducing the incidence of cyber crimes by eliminating fake and unauthorized identities.
                  </div>
                </div>
              </motion.div>
            </motion.div>

            <motion.div className="right-sub-box" variants={section3_styling.tickBoxVariants}>
              <motion.div className="tick-text" variants={section3_styling.tickItemVariants}>
                <div className="frame-numb">4</div>
                <div className="main-sub-heading-third-div">
                  <div className="sub-heading-third-div">Comprehensive Security</div>
                  <div className="sub-content-third-div">
                    Ensuring the solution is robust and scalable to protect against evolving cyber threats.
                  </div>
                </div>
              </motion.div>

              <motion.div className="tick-text" variants={section3_styling.tickItemVariants}>
                <div className="frame-numb">5</div>
                <div className="main-sub-heading-third-div">
                  <div className="sub-heading-third-div">User Trust and Safety</div>
                  <div className="sub-content-third-div">
                    Building trust among users by safeguarding their personal information and preventing misuse.
                  </div>
                </div>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </motion.div>
      <div className="section-4">
        <div className="section-4-inner-div">
          <div className="sub-right-div">
            <div className="upper-div">
              <div className="left-box-section-3">
              <div className='left-box-heading'>ALL-IN-ONE</div>
                <h2>
                  The Ultimate Solution -{" "}
                  <span className="company-name">Antro PAI</span>
                </h2>
                <p>
                  Antro provides a systematic approach to identity management,
                  aimed at reducing cyber crimes stemming from counterfeit or
                  unauthorized identities
                </p>
              </div>
            </div>
            <div className="lower-div">
              <div className="tick-text">
                <div className="frame-numb">
                  <FiCheckCircle />
                </div>
                <div className="main-sub-heading-third-div">
                  <div className="sub-heading-third-div">
                    Process-Driven Solution
                  </div>
                  <div className="sub-content-third-div">
                    Antro's system methodically verifies identities to
                    prevent fraud, ensuring authenticity at every stage.{" "}
                  </div>
                </div>
              </div>
              <div className="tick-text">
                <div className="frame-numb">
                  <FiCheckCircle />
                </div>
                <div className="main-sub-heading-third-div">
                  <div className="sub-heading-third-div">
                    Powered by PAI
                  </div>
                  <div className="sub-content-third-div">
                    Leveraging our patented Pre-Authenticated Identity
                    model, Antro uniquely maintains each individual's
                    identity within the system.{" "}
                  </div>
                </div>
              </div>
              <div className="tick-text">
                <div className="frame-numb">
                  <FiCheckCircle />
                </div>
                <div className="main-sub-heading-third-div">
                  <div className="sub-heading-third-div">
                    Dynamic Identity Verification
                  </div>
                  <div className="sub-content-third-div">
                    Our technology dynamically adjusts to verify identities,
                    preventing replication or hacking{" "}
                  </div>
                </div>
              </div>
              <div className="tick-text">
                <div className="frame-numb">
                  <FiCheckCircle />
                </div>
                <div className="main-sub-heading-third-div">
                  <div className="sub-heading-third-div">
                    Comprehensive Protection
                  </div>
                  <div className="sub-content-third-div">
                    Antro protects both individuals and officials, thwarting
                    unauthorized access and identity theft{" "}
                  </div>
                </div>
              </div>
              <div className="tick-text">
                <div className="frame-numb">
                  <FiCheckCircle />
                </div>
                <div className="main-sub-heading-third-div">
                  <div className="sub-heading-third-div">
                    User-Friendly Mobile App
                  </div>
                  <div className="sub-content-third-div">
                    The Antro app provides a seamless, secure user
                    experience, allowing real-time access to identity
                    protection features{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-5">
        <div className="upper-part">
        <div className='upper-part-sub-heading'>Cutting-Edge Capabilities</div>
          <span className="heading-firstpart">Antro in Action:</span>
          <span className="heading-secondpart">
            {" "}
            Unveiling Cutting-Edge Features
          </span>
        </div>

        <div className="middle-part">
          <div className="first-div">
            <div className="tick-text-1">
              <div className="frame-numb">
                <img src={IcRoundDomainVerification} alt="verification"/>
              </div>
              <div className="main-sub-heading-third-div">
                <div className="sub-heading-third-div">
                  Real-Time Verification
                </div>
                <div className="sub-content-third-div">
                  Instantly authenticate government officials, corporate
                  employees, and general users via the Antro Pre-Authenticated
                  Identity platform.
                </div>
              </div>
            </div>
            <div className="tick-text-2">
              <div className="frame-numb">
                <img src={ClarityNodesLine} alt="nodes"/>
              </div>
              <div className="main-sub-heading-third-div">
                <div className="sub-heading-third-div">Ecosystem Trust</div>
                <div className="sub-content-third-div">
                  Build trust among higher officials and end-users, proactively
                  preventing cyber crimes from fake identities.
                </div>
              </div>
            </div>
            <div className="tick-text-3">
              <div className="frame-numb">
                <img src={ComputerCloud} alt="cloud"/>
              </div>
              <div className="main-sub-heading-third-div">
                <div className="sub-heading-third-div">
                  Cloud-Based Platform
                </div>
                <div className="sub-content-third-div">
                  Utilize a centralized, cloud-based platform for streamlined
                  verification and enhanced security, anchored by the three
                  pillars of identity.
                </div>
              </div>
            </div>
          </div>
          <div className="second-div">
            <img src={Section5Img} alt="phonesection5image" />
          </div>
          <div className="third-div">
            <div className="tick-text-9">
              <div className="frame-numb">
                <img src={PhVault} alt="vault"/>
              </div>
              <div className="main-sub-heading-third-div">
                <div className="sub-heading-third-div">Antro Vault</div>
                <div className="sub-content-third-div">
                  Offer a centralized document repository for employees,
                  restricting document movement and minimizing associated risks
                  for both employers and employees.
                </div>
              </div>
            </div>
            <div className="tick-text-8">
              <div className="frame-numb">
                <img src={Flag02} alt="flag"/>
              </div>
              <div className="main-sub-heading-third-div">
                <div className="sub-heading-third-div">
                  Country-Specific Authentication
                </div>
                <div className="sub-content-third-div">
                  Verify users using country-specific authentication to confirm
                  individual authenticity.
                </div>
              </div>
            </div>
            <div className="tick-text-7">
              <div className="frame-numb">
                <img src={IconamoonShieldYesLight} alt="shield"/>
              </div>
              <div className="main-sub-heading-third-div">
                <div className="sub-heading-third-div">
                  Pre-Authenticated Access
                </div>
                <div className="sub-content-third-div">
                  Ensure secure, pre-authenticated access for employees,
                  including attendance management.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lower-part">
          <div className="tick-text-4">
            <div className="frame-numb">
              <img src={MaterialSymbolsQrCodeScannerRounded} alt="qrscanner"/>
            </div>
            <div className="main-sub-heading-third-div">
              <div className="sub-heading-third-div">
                Dynamic QR Code Generation
              </div>
              <div className="sub-content-third-div">
                Prevent identity theft with dynamically generated QR codes from
                Antro’s solution.
              </div>
            </div>
          </div>
          <div className="tick-text-5">
            <div className="frame-numb">
              <img src={UserSwitch} alt="user-switch"/>
            </div>
            <div className="main-sub-heading-third-div">
              <div className="sub-heading-third-div">
                Multi-Profiles/Personas
              </div>
              <div className="sub-content-third-div">
                Address diverse needs by linking unique users to multiple
                profiles/personas within the system.
              </div>
            </div>
          </div>
          <div className="tick-text-6">
            <div className="frame-numb">
              <img src={mdiInvoiceTextCheckOutline} alt="invoice"/>
            </div>
            <div className="main-sub-heading-third-div">
              <div className="sub-heading-third-div">
                Secure Check-In/Check-Out
              </div>
              <div className="sub-content-third-div">
                Employ dynamic QR entry systems powered by PAI for secure
                check-ins and check-outs.
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="section-6">
      <div className='section-6-sub-heading'>ANTRO-PAI PREVIEW</div>
        <div className="upper-part">
          <span className="heading-firstpart">Antro-PAI:</span>
          <span className="heading-secondpart"> A Visual Tour</span>
          <div className="star-section6">
            <img src={StarSection6} alt="starimg" />
          </div>
        </div>
        <div className="lower-part">
          <div className="carousel-wrapper">
            <MobileCarousel />
          </div>
        </div>
      </div>
      <div className="section-7">
      <div className='section-7-sub-heading'>WHAT SETS US APART</div>
        <div className="upper-part">
          <span className="heading-secondpart">
            {" "}
            Why <nbsp />
            <span className="heading-firstpart">Antro</span> <nbsp /> Stands
            Out: Our Unique Edge
          </span>
        </div>
        <div className="lower-part">
          <div className="left-div-lower">
            <div className="iphone">
              <img src={Iphoneimg2} alt="iphone"/>
            </div>
            <div className="right-div-lower">
              {" "}
              <div className="upper-part">
                <div className="tick-text-1">
                  <div className="frame-numb">
                    <img src={ThumbIcon} alt="thumbprint" />
                  </div>
                  <div className="main-sub-heading-third-div">
                    <div className="sub-heading-third-div">
                      Patented PAI Concept
                    </div>
                    <div className="sub-content-third-div">
                      Our proprietary Pre-Authenticated Identity model ensures distinct and protected identity verification.
                    </div>
                  </div>
                </div>
                <div className="tick-text-2">
                  <div className="frame-numb">
                    <img src={IdentityIcon} alt="thumbprint" />
                  </div>
                  <div className="main-sub-heading-third-div">
                    <div className="sub-heading-third-div">Unique Identity Maintenance</div>
                    <div className="sub-content-third-div">
                    Each individual's identity is meticulously preserved, preventing replication.
                    </div>
                  </div>
                </div>
                <div className="tick-text-3">
                  <div className="frame-numb">
                    <img src={TechIcon} alt="thumbprint" />
                  </div>
                  <div className="main-sub-heading-third-div">
                    <div className="sub-heading-third-div">
                      Advanced Technology
                    </div>
                    <div className="sub-content-third-div">
                      Dynamic identity verification mechanisms effectively deter hacking and duplication attempts.
                    </div>
                  </div>
                </div>
              </div>
              <div className="upper-part">
                <div className="tick-text-1">
                  <div className="frame-numb">
                    <img src={SecurityIcon} alt="thumbprint" />
                  </div>
                  <div className="main-sub-heading-third-div">
                    <div className="sub-heading-third-div">
                    Uncompromised Security
                    </div>
                    <div className="sub-content-third-div">
                    Our robust PAI model provides a formidable defense against unauthorized access and identity theft.
                    </div>
                  </div>
                </div>
                <div className="tick-text-2">
                  <div className="frame-numb">
                    <img src={ApplicationsIcon} alt="thumbprint" />
                  </div>
                  <div className="main-sub-heading-third-div">
                    <div className="sub-heading-third-div">Versatile Application</div>
                    <div className="sub-content-third-div">
                    Antro's solution offers secure identity verification services tailored for government officials, corporate employees, and general users alike.
                    </div>
                  </div>
                </div>
                <div className="tick-text-3">
                  <div className="frame-numb">
                    <img src={LensIcon} alt="thumbprint" />
                  </div>
                  <div className="main-sub-heading-third-div">
                    <div className="sub-heading-third-div">
                    Proactive Fraud Prevention
                    </div>
                    <div className="sub-content-third-div">
                      Antro's approach proactively addresses cyber crimes associated with fraudulent and unauthorized identities.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductInfo;
