import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteAccount } from "../utils/apis";
import { validateIdentifier, handleInputChange } from "../utils/validationHelpers";
import Modal from "../components/common/Modal";
import DeleteIcon from "../assets/images/delete-account/AccountDeleteIcon.svg";
import "../assets/styles/pages/AccountDeletion.scss";

const AccountDeletion = () => {
  const navigate = useNavigate();
  const [identifier, setIdentifier] = useState("");
  const [error, setError] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isScheduledModalOpen, setIsScheduledModalOpen] = useState(false);
  const [isDeletionInProgress, setIsDeletionInProgress] = useState(false);

  const handleDeleteAccount = async () => {
    setError(null);

    try {
      const response = await deleteAccount(identifier);

      if (response.success) {
        setIsDeletionInProgress(true);
        localStorage.setItem("isDeletionInProgress", "true");

        setIsConfirmModalOpen(false);
        setIsScheduledModalOpen(true);
      } else {
        setError(response.message || "Account deletion failed. Please try again.");
        setIsConfirmModalOpen(false);
      }
    } catch (error) {
      const errorMessage = error?.response?.message || "Account deletion failed. Please try again.";
      setError(errorMessage);
      setIsConfirmModalOpen(false);
    }
  };

  const closeScheduledModal = () => {
    setIsScheduledModalOpen(false);
    navigate("/");
  };

  return (
    <div className="page-wrapper">
      <div className="confirming-box">
        <div className="delete-account-container">
          <div className="delete-icon">
            <img src={DeleteIcon} alt="Delete Icon" />
          </div>
          <div className="heading">Delete Your Account</div>
          <div className="delete-text">
            Once you delete your account, all your information will be permanently removed from our servers.
          </div>
          {isDeletionInProgress ? (
            <p className="error-message">
              An account deletion request is already in progress. Please wait while we process your request.
            </p>
          ) : (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const validationError = validateIdentifier(identifier);
                if (validationError) {
                  setError(validationError);
                } else {
                  setIsConfirmModalOpen(true);
                }
              }}
            >
              <div className="username-input">
                <label>Username/Email/Phone Number</label>
                <input
                  type="text"
                  value={identifier}
                  onChange={(e) => {
                    const updatedValue = handleInputChange(e.target.value);
                    setIdentifier(updatedValue);
                    setError(validateIdentifier(updatedValue));
                  }}
                  maxLength={identifier.startsWith("+91") ? 13 : 30}
                  required
                  disabled={isDeletionInProgress}
                />
              </div>
              {error && <p className="error-message">{error}</p>}
              <button
                className="delete-btn"
                type="submit"
                disabled={!!error || isDeletionInProgress}
              >
                Delete
              </button>
            </form>
          )}
        </div>
      </div>

      <Modal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={handleDeleteAccount}
        title="Confirm Account Deletion"
        confirmText="Delete"
        cancelText="Cancel"
        icon="delete"
      >
        <p>
          Are you sure you want to delete your account? This action is irreversible and will permanently remove all your data.
        </p>
        {error && <p className="error-message">{error}</p>}
      </Modal>

      <Modal
        isOpen={isScheduledModalOpen}
        onClose={closeScheduledModal}
        title="Account Deletion Scheduled"
        icon="success"
        showConfirmButton={false}
      >
        <div className="modal-content">
          <p>Your account has been scheduled for deletion and will be permanently removed in 24 hours.</p>
        </div>
      </Modal>
    </div>
  );
};

export default AccountDeletion;
