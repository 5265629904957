import React, { useState} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Screen1 from "../../assets/images/product-info/section6-images1.svg";
import Screen2 from "../../assets/images/product-info/section6-images2.svg";
import Screen3 from "../../assets/images/product-info/section6-images3.svg";
import Screen5 from "../../assets/images/product-info/section6-images5.svg";
import Screen6 from "../../assets/images/product-info/section6-images6.svg";
import IphoneMockup from "../../assets/images/iphone-mockup.png";
import "../../assets/styles/components/mobilecarousel.scss";

const MobileCarousel = () => {
const [currentSlide, setCurrentSlide] = useState(0);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    centerPadding: '0px',
    beforeChange: (oldIndex, newIndex) => {
        setCurrentSlide(newIndex + 1);
      },
  };

  const slides = [
    // Replace with actual images or content that represent each slide.
    <div><img src={Screen1} alt="Slide 1" /></div>,
    <div><img src={Screen2} alt="Slide 2" /></div>,
    <div><img src={Screen3}alt="Slide 3" /></div>,
    // <div><img src={Screen4} alt="Slide 4" /></div>,
    <div><img src={Screen5} alt="Slide 5" /></div>,
    <div><img src={Screen6} alt="Slide 6" /></div>,
  ];

  return (
    <div className="mobile-carousel-container">
      {/* Fixed Phone Frame */}
      <div className="phone-frame">
        <img src={IphoneMockup} alt="Phone Frame" />
      </div>

      {/* Slider */}
      <div className="slider-wrapper">
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <div key={index} className={`carousel-slide ${index === currentSlide ? 'active-slide' : ''}`}>
              {slide}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default MobileCarousel;
