// Create a not found page
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchJoinOrganiationLocationLinkDetails } from '../utils/apis';
// import PadlockIcon from "../assets/images/Padlock.svg"
import '../assets/styles/pages/JoinLinkOrganisationLocation.scss';
import ProfileManager from '../components/specific/JoinOrganisationLinkProfileManager';

const JoinOrganisationLink = () => {
  const { token } = useParams();
  const [linkData, setLinkData] = useState({})

  useEffect(() => {
    if (!token) {
      window.location.href = '/invalid-code';
      console.log("Invalid token:", token);

    } else {
      checkLink(token);
    }
  }, [token]);

  const checkLink = async (token) => {
    try {
      const result = await fetchJoinOrganiationLocationLinkDetails(token);
      setLinkData(result.data)

      if (!result.success) {
        window.location.href = '/invalid-code';
      }
    } catch (error) {
      console.log('Error checking link:', error);
      window.location.href = '/invalid-code';
    }
  };

  return (
    <div className="join-link-organisation">
      {!linkData?.success && <div className="container">
        <h2 className='main-heading'>Organisation Onboarding</h2>
          <ProfileManager apiResponse={{ link_data: linkData, existing_user: linkData?.existing_user, existing_profile: linkData?.existing_profile, new_group_name: linkData.new_group_name, old_group_name: linkData.old_group_name }} />
      </div>}
    </div>
  );
}

export default JoinOrganisationLink;