import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../assets/styles/components/specific/ProfileManager.scss';
import UserNotFound from '../../assets/images/ProfileOnboard/user-not-found.jpeg';
import Ellipse from '../../assets/images/ProfileOnboard/ellipse.png';
import groupBg from '../../assets/images/ProfileOnboard/create-profile-group-bg.png'
import { IoIosPeople } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";
import { BsFillPersonFill } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";
import { RiErrorWarningFill } from "react-icons/ri";
import { NotificationManager } from 'react-notifications';
import { fetchJoinOrganisationLocations, fetchJoinOrganisationLocationsInner, JoinChatLink } from '../../utils/apis';
import playstoreIcon from "../../assets/images/Playstore.svg"
import appstoreIcon from "../../assets/images/Apple.svg"
import downloadIcon from "../../assets/images/instruction page/instruction-downlaod.png";

const ProfileManager = ({ apiResponse }) => {
    const { link_data, existing_user, existing_profile, new_group_name, old_group_name } = apiResponse;
    const [region, setRegion] = useState('');
    const [regions, setRegions] = useState([]);
    const [country, setCountry] = useState('');
    const [countries, setCountries] = useState([]);
    const [state, setState] = useState('');
    const [states, setStates] = useState([]);
    const [city, setCity] = useState('');
    const [cities, setCities] = useState([]);
    const [alias, setAlias] = useState('');
    const navigate = useNavigate();

    const handleRegionChange = async (e) => {

        setRegion(e.target.value);
        const response = await fetchJoinOrganisationLocationsInner(link_data?.request_data?.organization_fk, e.target.value);
        const countryList = response.data.map((location) => {
            return {
                name: location.name_nn,
                value: location.location_id
            }
        })
        setCountries(countryList)
        setCountry('');
    };

    const handleCountryChange = async (e) => {
        setCountry(e.target.value);
        const response = await fetchJoinOrganisationLocationsInner(link_data?.request_data?.organization_fk, e.target.value);
        const stateList = response.data.map((location) => {
            return {
                name: location.name_nn,
                value: location.location_id
            }
        })
        setStates(stateList)
        setState('');
    };

    const handleStateChange = async (e) => {
        setState(e.target.value);
        try {
            const response = await fetchJoinOrganisationLocationsInner(link_data?.request_data?.organization_fk, e.target.value);
            const cityList = response.data.map((location) => {
                return {
                    name: location.name_nn,
                    value: location.location_id
                }
            })
            setCities(cityList)
            setCity('');
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    const handleCityChange = async (e) => {
        setCity(e.target.value);
    };


    


    const handleCreateClick = async () => {
        try {
            const response = await JoinChatLink({
                code: link_data?.request_data.code,
                alias_nn: alias,
                location_id: link_data.request_data?.location_fk === undefined ? city : link_data.request_data.location_fk.location_id
            })
            if (response.success) {
                NotificationManager.success('Members added', 'Members added successfully. Please login in to you acount in Antro app.');
                window.location.href = '/';
            } else {
                NotificationManager.error(response.errors, 'Creating profile failed');
                console.log("Error: ", response);
            }
        } catch (error) {
            NotificationManager.error(error, 'Creating profile failed');
        }
    }
    const handleSwitchGroupClick = async () => {
        try {
            const response = await JoinChatLink({
                code: link_data?.request_data.code
            })
            if (response.success) {
                NotificationManager.success('You have been switched to a new group. Please login in to you acount in Antro app.', 'Group switched');
                window.location.href = '/';
            } else {
                NotificationManager.error('Group switch failed. Please try again later.', 'Group switch failed');
            }
        } catch (error) {
            NotificationManager.error('Group switch failed. Please try again later.', 'Group switch failed');
        }
    }

    const handleCancelClick = async () => {
        navigate("/");
    }

    useEffect(() => {
        const fetchJoinOrganisationLocationsHandler = async () => {
            try {
                const response = await fetchJoinOrganisationLocations(link_data?.request_data?.organization_fk);
                const regionsList = response.data.map((location) => {
                    return {
                        name: location.name_nn,
                        value: location.location_id
                    }
                })
                setRegions(regionsList)
            } catch (error) {
                console.log("Error: ", error);
            }
        };

        if (existing_user && !existing_profile) {
            fetchJoinOrganisationLocationsHandler()
        }
    }, [existing_user, existing_profile,link_data]);

    return (
        <div className="profile-manager">
           {!new_group_name && (
    <div className="loader-container">
        <div className="loader"></div>
    </div>
)}

            
            {new_group_name && !existing_user && (

            <div className='no-user'>
                <div className='user-not-found-image'>
                    <img src={UserNotFound} alt='no-user-image'/>
                </div>
                <div className="message-box">
                    <h3>Whoops!</h3>
                    <h3>You need to create an account to use this link</h3>
                    <p>Please go to the Antro app and create a user account with the same email address. Then come back here and use this link.</p>
                </div>
                <div>
                <div className="card">
                    <div className="card-icon">
                      <img src={downloadIcon} alt="Download Icon" />
                    </div>
                    <h3>Download the Antro App</h3>
                    <div className="store-links">
                      {/* Play Store Button */}
                      <a
                        href="https://play.google.com/store/apps/details?id=com.antro"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="store-button-link"
                      >
                        <button className="store-button">
                          <div className="store-button-icon">
                            <img
                              src={playstoreIcon}
                              alt="Get it on Google Play"
                              className="store-icon"
                            />
                            <div className="store-button-title">
                              <span className="store-button-text">Get it on</span>
                              <span className="store-button-name">Play Store</span>
                            </div>
                          </div>
                        </button>
                      </a>

                      {/* App Store Button */}
                      <a
                        href="https://apps.apple.com/in/app/antro/id6503100275"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="store-button-link"
                      >
                        <button className="store-button">
                          <div className="store-button-icon">
                            <img
                              src={appstoreIcon}
                              alt="Get it on App Store"
                              className="store-icon"
                            />
                            <div className="store-button-title">
                              <span className="store-button-text">Get it on</span>
                              <span className="store-button-name">App Store</span>
                            </div>
                          </div>
                        </button>
                      </a>
                    </div>
                    <p>
                      Use the above link or Visit the App Store/Google Play on your mobile device.
                      Search for “Antro” and download the app.
                    </p>
                  </div>
                    </div>
            </div>
            )}
             {existing_user && existing_profile && old_group_name && (
                <div className="switch-group">
                    <h2>Switch Group</h2>
                    <p>Easily Navigate Between Your Groups</p>
                    <div className="group-card old-group">
                        <h3>Old Group</h3>
                        <div className='group-details-card-inner' style={{ backgroundImage: `url(${groupBg})` }}>
                            <div className='group-details-card' >
                                <div className='group-details-card-a' style={{ backgroundImage: `url(${Ellipse})` }}>
                                    <div className='group-icon'>
                                        <IoIosPeople />
                                    </div>
                                    <div className='group-name-details'>
                                        <div className='name'>{old_group_name || "Antro Group"}</div>
                                        <div className='company'>{link_data?.request_data?.location_fk?.organisation_fk?.name_nn || "Antro PAI Technologies Pvt. Ltd."}</div>
                                    </div>
                                    <div className='group-location'><IoLocationSharp /> {link_data?.request_data?.location_fk?.name_nn || "India"}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="group-card new-group">
                        <h3>New Group</h3>
                        <div className='group-details-card-inner' style={{ backgroundImage: `url(${groupBg})` }}>
                            <div className='group-details-card' >
                                <div className='group-details-card-a' style={{ backgroundImage: `url(${Ellipse})` }}>
                                    <div className='group-icon'>
                                        <IoIosPeople />
                                    </div>
                                    <div className='group-name-details'>
                                        <div className='name'>{new_group_name || "Antro Group"}</div>
                                        <div className='company'>{link_data?.request_data?.location_fk?.organisation_fk?.name_nn || "Antro PAI Technologies Pvt. Ltd."}</div>
                                    </div>
                                    <div className='group-location'><IoLocationSharp /> {link_data?.request_data?.location_fk?.name_nn || "India"}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="warning-message">
                        <div>
                            <RiErrorWarningFill />
                        </div>
                        <div>
                            <div><strong>This is a warning message</strong></div>
                            <div className='sub-line'>Switching groups will lose unsaved changes. Continue?</div>
                        </div>
                    </div>
                    <div className='functional-btns'>
                        <button className="functional-button cancel" onClick={handleCancelClick}>Cancel</button>
                        <button className={"functional-button switch"} onClick={handleSwitchGroupClick}>Switch Group</button>
                    </div>
                </div>
            )}
                {existing_user && !existing_profile && (
                <div className="create-profile">
                    <h2 className='create-profile-heading'>Create Profile</h2>
                    <p className='create-profile-subheading'>Set Up Your Profile and Get Started</p>
                    <div className="group-details-card-outer">
                        <h3>Group Details</h3>
                        <div className='group-details-card-inner' style={{ backgroundImage: `url(${groupBg})` }}>
                            <div className='group-details-card' >
                                <div className='group-details-card-a' style={{ backgroundImage: `url(${Ellipse})` }}>
                                    <div className='group-icon'>
                                        <IoIosPeople />
                                    </div>
                                    <div className='group-name-details'>
                                        <div className='name'>{new_group_name || "Antro Group"}</div>
                                        <div className='company'>{link_data?.request_data?.location_fk?.organisation_fk?.name_nn || "Antro PAI Technologies Pvt. Ltd."}</div>
                                    </div>
                                    <div className='group-location'><IoLocationSharp /> {link_data?.request_data?.location_fk?.name_nn || "India"}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form className="personal-information">
                        <h3 style={{ marginBottom: "0px" }}><BsFillPersonFill style={{ color: "#32a0ef", marginRight: "10px" }} /> Personal Information</h3>
                        <div className="personal-info-outer">
                            <div className='personal-info-label-data-outer'>
                                <div className='personal-info-label-data'>
                                    <label>Alias/Nickname</label>
                                    <div className='personal-info-data' style={{ width: "50%", marginLeft: "0px" }}>
                                        <input type="text" value={alias} onChange={(e) => setAlias(e.target.value)} style={{ padding: "10px 10px", width: "90%" }} />
                                    </div>
                                </div>
                            </div>
                            <div className='personal-info-label-data-outer' style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                <div className='personal-info-label-data'>
                                    <label>Full Name</label>
                                    <div className='personal-info-data'>{link_data?.user_data?.first_name || "First Name"}</div>
                                </div>
                                <div className='personal-info-label-data'>
                                    <label>Last Name</label>
                                    <div className='personal-info-data'>{link_data?.user_data?.last_name || "Last Name"}</div>
                                </div>
                            </div>
                            <div className='personal-info-label-data-outer' style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                <div className='personal-info-label-data'>
                                    <label>Email</label>
                                    <div className='personal-info-data'>{link_data?.user_data?.email || "userxxx@xx.com"}</div>
                                </div>
                                <div className='personal-info-label-data'>
                                    <label>Phone Number</label>
                                    <div className='personal-info-data'>{link_data?.user_data?.phone_number || "+91 xxxxxxxxxx"}</div>
                                </div>
                            </div>
                        </div>
                        {/* <input type="email" placeholder="Enter Email ID" value={link_data?.user_data?.email} disabled/>
                        <input type="text" placeholder="Enter Phone Number" value={link_data?.user_data?.phone_number} disabled/>
                        <input type="text" placeholder="Enter First Name" value={link_data?.user_data?.first_name} disabled/>
                        <input type="text" placeholder="Enter Last Name" value={link_data?.user_data?.last_name} disabled/> */}
                    </form>
                    {link_data.request_data?.location_fk === undefined ? <form className="location-information">
                        <h3>Location</h3>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                            <div style={{ flex: '1 1 45%' }}>
                                <select value={region} onChange={(e) => handleRegionChange(e)} style={{ width: '100%' }}>
                                    <option value="">Select Region</option>
                                    {regions.map((regionData) => (
                                        <option key={regionData.value} value={regionData.value}>{regionData.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div style={{ flex: '1 1 45%' }}>
                                <select value={country} onChange={(e) => handleCountryChange(e)} style={{ width: '100%' }}>
                                    <option value="">Select Country</option>
                                    {countries.map((countryData) => (
                                        <option key={countryData.value} value={countryData.value}>{countryData.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div style={{ flex: '1 1 45%' }}>
                                <select value={state} onChange={(e) => handleStateChange(e)} style={{ width: '100%' }}>
                                    <option value="">Select State</option>
                                    {states.map((stateData) => (
                                        <option key={stateData.value} value={stateData.value}>{stateData.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div style={{ flex: '1 1 45%' }}>
                                <select value={city} onChange={(e) => handleCityChange(e)} style={{ width: '100%' }}>
                                    <option value="">Select City</option>
                                    {cities.map((cityData) => (
                                        <option key={cityData.value} value={cityData.value}>{cityData.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                    </form> : <div className="location-information">
                        <h3><FaLocationDot style={{ color: "#32a0ef", marginRight: "10px" }} /> Location</h3>
                        <div className='location-information-inner'>
                            You will be added to <span style={{ fontWeight: "bold" }}>{link_data.request_data?.location_fk.name_nn}</span>
                        </div>
                    </div>}
                    <div className='functional-btns'>
                        <button className="functional-button cancel" onClick={handleCancelClick}>Cancel</button>
                        <button className={alias.length < 3 ? "functional-button create disabled" : "functional-button create"} onClick={handleCreateClick}>Create</button>
                    </div>
                </div>
            )}
            {existing_user && existing_profile && !old_group_name &&(
                <div className="switch-group">
                <h2>Join Group</h2>
                <p>Seamlessly join and collaborate with your group members.</p>
                
                <div className="group-card new-group">
                    <h3>New Group</h3>
                    <div className='group-details-card-inner' style={{ backgroundImage: `url(${groupBg})` }}>
                        <div className='group-details-card' >
                            <div className='group-details-card-a' style={{ backgroundImage: `url(${Ellipse})` }}>
                                <div className='group-icon'>
                                    <IoIosPeople />
                                </div>
                                <div className='group-name-details'>
                                    <div className='name'>{new_group_name || "Antro Group"}</div>
                                    <div className='company'>{link_data?.request_data?.location_fk?.organisation_fk?.name_nn || "Antro PAI Technologies Pvt. Ltd."}</div>
                                </div>
                                <div className='group-location'><IoLocationSharp /> {link_data?.request_data?.location_fk?.name_nn || "India"}</div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='functional-btns'>
                    <button className="functional-button cancel" onClick={handleCancelClick}>Cancel</button>
                    <button className={"functional-button switch"} onClick={handleSwitchGroupClick}>Join Group</button>
                </div>
            </div>
            )}
        </div>
    );
};

export default ProfileManager;
