import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FiGlobe } from 'react-icons/fi';
import { fetchResetLinkData, updateUserDetails, checkUserName } from "../utils/apis"; // Import checkUserName API
import AntroLogo from "../assets/images/antrologo.png";
import { toast, ToastContainer } from "react-toastify";
import PadlockIcon from "../assets/images/Padlock.svg";
import "../assets/styles/pages/UpdateUsername.scss";

const UpdateUsername = () => {
  const { token } = useParams();
  const [secretCode, setSecretCode] = useState("");
  const [oldUsername, setOldUsername] = useState("");
  const [newUsername, setNewUsername] = useState("");
  // const [suggestedUsernames, setSuggestedUsernames] = useState([]);
  const [errors, setErrors] = useState({ oldUsername: [], newUsername: [] });
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [touched, setTouched] = useState({ oldUsername: false, newUsername: false });
  const [showVisitWebsite, setShowVisitWebsite] = useState(false);

  useEffect(() => {
    if (!token) {
      window.location.href = "/invalid-code";
    } else {
      setSecretCode(token);
      checkLink(token);
    }
  }, [token]);

  const checkLink = async (token) => {
    try {
      const data_type = "update_username";
      const result = await fetchResetLinkData(data_type, token);
      if (!result.success && !result.errors.link_verified) {
        window.location.href = "/invalid-code";
      }
    } catch (error) {
      console.log("Error checking link:", error);
      window.location.href = "/invalid-code";
    }
  };

  const validateOldUsername = (username) => {
    const errors = [];
    if (username.length === 0) errors.push("Old username is required.");
    return errors;
  };

  const validateUsername = async (username) => {
    let errors = [];

    // Basic validation
    if (username.length === 0) errors.push("New username is required.");
    if (/\s/.test(username)) errors.push("Username cannot contain spaces.");
    if (/[^a-zA-Z0-9_]/.test(username)) {
      errors.push("Username can only contain letters, numbers, and underscores.");
    }
    if (username.length < 5) errors.push("Username must be at least 5 characters long.");
    if (username.length > 30) errors.push("Username must not be more than 30 characters long.");

    // Call the API only if the basic validation passed
    if (errors.length === 0) {
      try {
        const response = await checkUserName(username);
        if (!response?.data?.available) {
          errors.push("Username is already taken.");
          // suggestUsernames(username);
        }
      } catch (error) {
        console.log("Error checking username availability:", error);
        errors.push("Error checking username availability.");
      }
    }

    return errors;
  };

  // const suggestUsernames = (username) => {
  //   const suggestions = [];
  //   for (let i = 1; i <= 3; i++) {
  //     suggestions.push(`${username}_${i}`);
  //   }
  //   setSuggestedUsernames(suggestions);
  // };

  

  useEffect(() => {
    const checkFormValidity = async () => {
      const oldUsernameErrors = validateOldUsername(oldUsername);
      const usernameErrors = await validateUsername(newUsername);
  
      if (oldUsername === newUsername && oldUsername.length > 0) {
        usernameErrors.push("Old and new username cannot be the same.");
      }
  
      setErrors({
        oldUsername: oldUsernameErrors,
        newUsername: usernameErrors,
      });
  
      setIsSubmitEnabled(
        oldUsernameErrors.length === 0 &&
        usernameErrors.length === 0 &&
        oldUsername !== newUsername
      );
    };
    checkFormValidity();
  }, [oldUsername, newUsername]);

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
    setTouched({ ...touched, [event.target.id]: true });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const oldUsernameErrors = validateOldUsername(oldUsername);
    const usernameErrors = await validateUsername(newUsername);

    if (oldUsername === newUsername) {
      usernameErrors.push("Old and new username cannot be the same.");
    }

    setErrors({
      oldUsername: oldUsernameErrors,
      newUsername: usernameErrors,
    });

    if (oldUsernameErrors.length > 0 || usernameErrors.length > 0) {
      return;
    }

    try {
      const data_type = "update_username";
      const result = await updateUserDetails(data_type, secretCode, newUsername, oldUsername);

      if (result.success) {
        toast.success('Your username has been updated successfully');
        
        document.querySelector(".container").innerHTML = `
          <h2>Username Updated</h2>
          <p>Your username has been successfully updated.</p>
        `;
        setShowVisitWebsite(true);
        // setTimeout(() => {
        //   navigate("/");
        // }, 5000);
      } else {
        toast.dismiss();
        toast.error(result.errors?.error || result.message || "An error occurred.");
      }
    } catch (error) {
      console.log("API Error: ", error);
      toast.error(error?.response?.data?.message || "An unexpected error occurred.");
      document.querySelector(".container").innerHTML = `
        <h2>Username Update Failed</h2>
        <p>Your username was not updated successfully. Please try again.</p>
      `;
    }
  };

  return (
    <div className="main-update-username">
      <div className="header">
        <img src={AntroLogo} alt="Antro Logo" className="logo" />
      </div>
      <img src={PadlockIcon} alt="Padlock Icon" className="lock-icon" />
      <div className="container">
        <h2>Update Username</h2>
        <form id="newPasswordForm" className="form-corporate-newUsername" onSubmit={handleSubmit}>
          <div className="input-wrapper">
            <input
              className={`username-input ${errors.oldUsername.length > 0 ? 'error' : ''}`}
              type="text"
              id="oldUsername"
              maxLength="30"
              placeholder="Old Username"
              value={oldUsername}
              onChange={handleInputChange(setOldUsername)}
            />
          </div>
          {touched.oldUsername && errors.oldUsername.length > 0 && (
            <div className="error-message">
              {errors.oldUsername.map((error, index) => <div key={index}>{error}</div>)}
            </div>
          )}
          <div className="input-wrapper">
            <input
              className={`username-input ${errors.newUsername.length > 0 ? 'error' : ''}`}
              type="text"
              id="newUsername"
              maxLength="30"
              placeholder="New Username"
              value={newUsername}
              onChange={handleInputChange(setNewUsername)}
              onBlur={async () => {
                const usernameErrors = await validateUsername(newUsername);
                if (oldUsername === newUsername && oldUsername.length > 0) {
                  usernameErrors.push("Old and new username cannot be the same.");
                }
                setErrors({ ...errors, newUsername: usernameErrors });
                setIsSubmitEnabled(
                  errors.oldUsername.length === 0 &&
                  usernameErrors.length === 0 &&
                  oldUsername !== newUsername
                );
              }}
            />
          </div>
          {touched.newUsername && errors.newUsername.length > 0 && (
            <div className="error-message">
              {errors.newUsername.map((error, index) => <div key={index}>{error}</div>)}
              {/* {suggestedUsernames.length > 0 && (
                <div className="suggestions">
                  <p>Suggested Usernames:</p>
                  {suggestedUsernames.map((suggestion, index) => (
                    <div key={index} className="suggestion" onClick={() => setNewUsername(suggestion)}>
                      {suggestion}
                    </div>
                  ))}
                </div>
              )} */}
            </div>
          )}
          <div className="main-submit-btn">
          <button type="submit" id="submitButton" disabled={!isSubmitEnabled}>
            Submit
          </button>
          </div>
        </form>
      </div>
      {showVisitWebsite && (
            <div className="visit-website">
              <div className="visit-website-content">
                <p className="visit-website-text">
                  Learn more about our products and services on our official website.
                </p>
                <button type="button" className="visit-website-button"
                  onClick={() => window.location.href = '/'}
                >
                  <FiGlobe className="button-icon" />
                  Visit Antrocorp Website
                </button>
              </div>
            </div>
            )}
      <ToastContainer />
    </div>
  );
};

export default UpdateUsername;
