import React from 'react';
import AntroLogo from "../../assets/images/antrologo.png"
import PadlockIcon from "../../assets/images/Padlock.svg"
import '../../assets/styles/pages/UpdateUsername.scss';

const InvalidCode = () => {
  return (
    <div className="main-update-username">
      <div className="header">
        <img src={AntroLogo} alt="Antro Logo" className="logo" />
      </div>
      <img src={PadlockIcon} alt="Padlock Icon" className="lock-icon" />
      <div className="container">
        <h2>Invalid Link</h2>
        <p>The link you used is either invalid or has expired. Please check the link or request a new one.</p>
      </div>
    </div>

  );
}

export default InvalidCode;